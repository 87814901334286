<template>
  <div class="order-container">
    <div class="queryItems mb10">
      <el-input v-model="querys.code" @clear="clearQuery" size="mini" class="mr15" clearable style="width: 20rem" placeholder="订单号"></el-input>
      <el-input v-model="querys.rem" @clear="clearQuery" size="mini" class="mr15" clearable style="width: 20rem" placeholder="备注"></el-input>
      <el-button type="primary" class="mr15" size="mini" icon="el-icon-search" @click="getData">{{ $t('domestic.search') }}</el-button>
      <el-button type="primary" class="mr15" size="mini" icon="el-icon-refresh" @click="resetForm">重置</el-button>
    </div>
    <div class="queryItems">
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">所属店铺：</div>
        <el-select size="small" @clear="clearQuery" v-model="querys.ShopeeId" clearable style="width: 20rem">
          <el-option
              v-for="item in options['shopee']"
              :key="item.Id"
              :label="item.ElectronicShopName"
              :value="item.Id">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">打单状态：</div>
        <el-select size="small" @clear="clearQuery" v-model="querys.blackmail" clearable style="width: 20rem">
          <el-option
              v-for="item in options['blackmail']"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">订单状态：</div>
        <el-select size="small" @clear="clearQuery" v-model="querys.orderType" clearable style="width: 20rem">
          <el-option
              v-for="item in options['orderType']"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">处理状态：</div>
        <el-select size="small" @clear="clearQuery" v-model="querys.handleType" clearable style="width: 20rem">
          <el-option
              v-for="item in options['handleType']"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">国内单号：</div>
        <el-select size="small" @clear="clearQuery" v-model="querys.odd" clearable style="width: 20rem">
          <el-option
              v-for="item in options['odd']"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">承运商：</div>
        <el-select size="small" @clear="clearQuery" v-model="querys.carrier" clearable style="width: 20rem">
          <el-option
              v-for="item in options['carrier']"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">目的地：</div>
        <el-select size="small" @clear="clearQuery" v-model="querys.destination" clearable style="width: 20rem">
          <el-option
              v-for="item in options['destination']"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">所属仓库：</div>
        <el-select size="small" @clear="clearQuery" v-model="querys.warehouse" clearable style="width: 20rem">
          <el-option
              v-for="item in options['warehouse']"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">库存出货：</div>
        <el-select size="small" @clear="clearQuery" v-model="querys.Shipment" clearable style="width: 20rem">
          <el-option
              v-for="item in options['Shipment']"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">物流状态：</div>
        <el-select size="small" @clear="clearQuery" v-model="querys.warehouse" clearable style="width: 20rem">
          <el-option
              v-for="item in options['warehouse']"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">下单时间：</div>
        <el-date-picker
            @clear="clearQuery"
            size="small"
            v-model="querys.dateRange"
            type="datetimerange"
            :range-separator="$t('order.separator')"
            :start-placeholder="$t('order.startTime')"
            :end-placeholder="$t('order.endTime')">
        </el-date-picker>
      </div>
    </div>
    <el-table
        :data="tableData"
        border
        :height="tableHeight"
        :max-height="tableHeight"
        style="width: 100%"
        default-expand-all
        v-loading="loading"
    >
      <el-table-column type="expand">
        <template slot-scope="scope">
          <el-table
              :data="scope.row.OrderItemDetails"
              border
              row-class-name="success-row"
              header-cell-class-name="header-row"
              style="width: 100%"
          >
            <el-table-column label="商品标题" prop="ItemName" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="品名/规格" prop="GoodsName" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="照片" align="center" width="80px">
              <template slot-scope="slotScope">
                <el-image class="shopee-img" :preview-src-list="scope.row.OrderItemDetails.map(item => { return item.Img })" :src="slotScope.row.Img" v-if="slotScope.row.Img"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="SKU" prop="Sku" show-overflow-tooltip align="center"></el-table-column>
<!--            <el-table-column label="是否绑定库存" show-overflow-tooltip align="center">-->
<!--              <template slot-scope="slotScope">-->
<!--                <span>{{ slotScope.row.IsBoundStock | formatType }}</span>-->
<!--              </template>-->
<!--            </el-table-column>-->
            <el-table-column label="数量" prop="Amount" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="价格" prop="Price" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="库存状态" prop="StockStateTxt" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="库存数量/冻结数量" show-overflow-tooltip align="center">
              <template slot-scope="slotScope">
                <span>{{ `${slotScope.row.StockQuantity}/${slotScope.row.FrozenQuantity}` }}</span>
              </template>
            </el-table-column>
            <el-table-column label="可出货数量" prop="ShippingQuantity" show-overflow-tooltip align="center"></el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="订单号" prop="MainBillCode" show-overflow-tooltip align="center" width="220">
        <template slot-scope="scope">
          <span>{{ scope.row.MainBillCode }}</span><span :style="{ color: scope.row.StockState === 1 ? 'green' : 'red' }" v-if="scope.row.StockState">/{{ scope.row.StockStateTxt	 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="店铺名称" show-overflow-tooltip prop="ShopeeName" align="center" width="150"></el-table-column>
      <el-table-column :label="$t('order.warehouse')" prop="WareHouseName" align="center"></el-table-column>
      <el-table-column :label="$t('order.carrier')" width="135" show-overflow-tooltip prop="CarrierName" align="center"></el-table-column>
      <el-table-column show-overflow-tooltip prop="CarrierBillCode" :label="$t('order.carrierbillcode')" align="center" width="130px">
      </el-table-column>
      <el-table-column :label="$t('order.timer')" show-overflow-tooltip prop="TimerName" align="center"></el-table-column>
      <el-table-column :label="$t('order.goodstype')" prop="GoodsTypeName" align="center"></el-table-column>
      <el-table-column label="派件人" prop="DeliveryName" align="center"></el-table-column>
      <el-table-column label="物流状态" show-overflow-tooltip prop="TrackContent" align="center"></el-table-column>
      <el-table-column :label="$t('order.Weight')" prop="Weight" align="center"></el-table-column>
      <el-table-column label="下单时间" width="160" prop="CreateTime	" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.CreateTime | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单过期时间" width="160" prop="OrderExpiredTime	" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.OrderExpiredTime | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          align="center"
          label="操作"
          width="160">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="byteToPdf(scope.row.Id)">拣货单</el-button>
          <el-button class="mr10" type="text" size="small">库存出货</el-button>
          <el-popover
              placement="bottom"
              trigger="click">
            <div>
              <el-button slot="reference" type="text" size="mini">修改收货地址</el-button>
              <el-button slot="reference" type="text" size="mini">绑定快递单号</el-button>
              <el-button slot="reference" type="text" size="mini">更改货物类型</el-button>
              <el-button slot="reference" type="text" size="mini">出货查看</el-button>
            </div>
            <el-button slot="reference" type="text" size="mini">更多</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="mt20"
        background
        @size-change="handleSizeChange"
        @current-change="handleChange"
        :current-page="PageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="PageRows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import {getOrderList, getSheetByte, getShopeeList} from "@/api/member";
import printJS from "print-js";

export default {
  data() {
    return {
      queryCode: "",
      PageIndex: 1,
      PageRows: 10,
      total: 0,
      loading: false,
      tableData: [],
      querys: {
        dateRange: []
      },
      options: {
        blackmail: [
          {
            label: "未打单",
            value: false
          },
          {
            label: "已打单",
            value: true
          }
        ],
        orderType: [
          {
            label: "未取消",
            value: false
          },
          {
            label: "已取消",
            value: true
          }
        ],
        handleType: [
          {
            label: "未处理",
            value: false
          },
          {
            label: "已处理",
            value: true
          }
        ],
        Shipment: [
          {
            label: "未出货",
            value: false
          },
          {
            label: "已出货",
            value: true
          }
        ],
        odd: [
          {
            label: "未绑定",
            value: false
          },
          {
            label: "已绑定",
            value: true
          }
        ]
      }
    }
  },
  created() {
    this.getData()
    this.selectOptionsInit()
  },
  filters: {
    formatType(val) {
      if (val) {
        return "是"
      } else {
        return "否"
      }
    }
  },
  computed: {
    tableHeight() {
      return 'calc(100vh - 16.5rem - 216px)'
    },
  },
  methods: {
    handleChange(val) {
      this.PageIndex = val
      this.getData()
    },
    selectOptionsInit() {
      this.getShopeeList()
      this.$store.dispatch('webSite/getCarrier').then(data => {
        this.options['carrier'] = data
      })
      this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.options['warehouse'] = data
      })
      this.$store.dispatch('webSite/getCountry').then(data => {
        this.options['destination'] = data
      })
    },
    clearQuery() {
      this.PageIndex = 1
      this.getData()
    },
    resetForm() {
      this.querys = {
        dateRange: []
      }
      this.clearQuery()
    },
    handleSizeChange(val) {
      this.PageRows = val
      this.PageIndex = 1;
      this.getData()
    },
    getShopeeList() {
      const data = {
        PageIndex: 1,
        PageRows: 99
      }
      getShopeeList(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.options['shopee'] = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    byteToPdf(orderId) {
      const data = {
        OrderIds: [orderId],
        TemplateName: "虾皮拣货单"
      }
      getSheetByte(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          // const pdfurl = `data:application/pdf;base64,${Data[0]}`
          printJS({
            printable: Data.Files[0],
            type: 'pdf',
            base64: true
          })
        } else {
          this.$message.warning(Msg)
        }

      })
    },
    getData() {
      let QueryCodes = this.querys.code ? [this.querys.code] : undefined
      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
        Codes: [],
        Type: this.orderType,
        QueryCodeType: 3,
        QueryCodes: QueryCodes,
        CarrierId: this.querys.carrier || undefined,
        WareHouseIds: this.querys.warehouse ? [this.querys.warehouse] : undefined,
        DestinationId: this.querys.destination || undefined,
        StartTime: this.querys.dateRange && this.querys.dateRange.length ? this.querys.dateRange[0] : undefined,
        EndTime: this.querys.dateRange && this.querys.dateRange.length ? this.querys.dateRange[1] : undefined,
        QueryType: 1,
        OrderSource: 4,
        Rem: this.querys.rem
      }
      this.loading = true
      getOrderList(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.total = Total
          this.tableData = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  }
}

</script>

<style scoped lang="scss">
::v-deep .success-row {
  background: #f0f9eb;
}
::v-deep .header-row {
  background: oldlace !important;
}
::v-deep .el-table__cell {
  padding: 0.8rem 0;
}
::v-deep .el-table .cell {
  font-size: 1.4rem;
}
.shopee-img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
}
.order-container {
  .queryItems {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    align-items: center;
  }
  padding: 2rem 2.5rem;
  .merger-btn {
    background-color: #194D8F;
    width: 16rem;
  }
  .arrived-box {
    margin: 0 auto;
    width: 7.5rem;
    height: 3.2rem;
    line-height: 3.2rem;
    background: #19B14C;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1.4rem;
  }
  .unarrive-box {
    margin: 0 auto;
    width: 7.5rem;
    height: 3.2rem;
    line-height: 3.2rem;
    background: #ED4014;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1.4rem;
  }
}
</style>
